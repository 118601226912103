import Cal, { getCalApi } from "@calcom/embed-react"
import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"

export default function Bbl() {
   const location = useLocation()

  const [referrer, setReferrer] = useState("zenith")

  useEffect(() => {
    const queryString = location.search

    const params = new URLSearchParams(queryString)

    const referrer_id = params.get("ref")
    if (referrer_id) {
      setReferrer(referrer_id)
    }
  }, [location.search])

  useEffect(() => {
    ;(async function () {
      const cal = await getCalApi()
      cal("ui", {
        styles: {
          branding: { brandColor: "#000000" },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      })
    })()
  }, [])

  return (
    <Cal
      calLink="zenith/bpa-consultation"
      style={{ width: "100%", height: "100%", overflow: "scroll" }}
      config={{
        layout: "month_view",
        referral: referrer,
      }}
    />
  )
}
